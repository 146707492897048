import axios from 'axios'
import { eventBus } from '@/event'
import { EVENT_GLOBAL_TOAST } from '@/event/event_types'
import { SiteStateModule } from '@/store/site_state'
import { common, randomUUID } from './common'
import { I18n as i18n } from '@/i18n'
const isTokenSec = true

const randomToken = randomUUID()

//全局的请求拦截器
axios.interceptors.request.use(
  function (config) {
    if (
      SiteStateModule.access_token !== undefined &&
      config.headers['token'] === undefined
    ) {
      config.headers['token'] = SiteStateModule.access_token
    } else {
      config.headers['token'] = randomToken
    }
    config.headers['isTokenSec'] = isTokenSec
    if (config.data !== null && config.headers['isTokenSec'] === true) {
      if (
        JSON.stringify(config.data) !== null &&
        JSON.stringify(config.data) !== '{}'
      ) {
        config.data = {
          rawData: common.extStringify(
            config.data,
            SiteStateModule.access_token || randomToken
          )
        }
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

function handleErrorStatusCode(responseData: any) {
  console.debug('handleErrorStatusCode', responseData)
  switch (responseData.code) {
    case 0:
      console.log('what happend')
      break

    default:
      eventBus.$emit(EVENT_GLOBAL_TOAST, {
        level: 'error',
        msg: i18n.t(responseData.msg),
        args: responseData.msgArgs
      })
      console.log('what happend')
      break
  }
}

axios.interceptors.response.use(
  function (res) {
    if (isTokenSec) {
      if (res.data.hasOwnProperty('rawData')) {
        res.data = common.extParse(
          res.data.rawData,
          SiteStateModule.access_token || randomToken
        )
      }
    }
    if (res.data && res.data.code !== 0) {
      if (res.data.code === 2023) {
        eventBus.$emit(EVENT_GLOBAL_TOAST, {
          level: 'error',
          msg: i18n.t('Old.password.error')
        })
      } else if (res.data.code === 1004) {
        SiteStateModule.logout()
      } else {
        handleErrorStatusCode(res.data)
      }
    }
    return res
  },
  function (error) {
    console.error('request error', error)
    return Promise.reject(error)
  }
)

const HttpPlugin = {
  install(Vue: any) {
    Vue.prototype.$http = axios
  }
}

export default HttpPlugin

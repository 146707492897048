export const common: any = {
  extParse(a: any, b: any) {
    if (!b) {
      b = 'e49775895556387b90e4434593d9874f'
    }
    return JSON['\x70\x61\x72\x73\x65'](
      common['\x64\x72\x65\x61\x6d']['\x65\x6e\x63']['\x55\x74\x66\x38'] //common['dream']['elc']['tf']
        ['\x73\x74\x72\x69\x6e\x67\x69\x66\x79'](
          common['\x64\x72\x65\x61\x6d']['\x41\x45\x53'][
            '\x64\x65\x63\x72\x79\x70\x74'
          ](
            a,
            common['\x64\x72\x65\x61\x6d']['\x65\x6e\x63']['\x55\x74\x66\x38'][
              '\x70\x61\x72\x73\x65'
            ](b),
            {
              mode: common['\x64\x72\x65\x61\x6d']['\x6d\x6f\x64\x65'][
                '\x45\x43\x42'
              ],
              padding:
                common['\x64\x72\x65\x61\x6d']['\x70\x61\x64'][
                  '\x50\x6b\x63\x73\x37'
                ]
            }
          )
        )
        ['\x74\x6f\x53\x74\x72\x69\x6e\x67']()
    )
  },

  extStringify(a: any, b: any) {
    if (!b) {
      b = 'e49775895556387b90e4434593d9874f'
    }
    return common['\x64\x72\x65\x61\x6d']['\x41\x45\x53']
      ['\x65\x6e\x63\x72\x79\x70\x74'](
        common['\x64\x72\x65\x61\x6d']['\x65\x6e\x63']['\x55\x74\x66\x38'][
          '\x70\x61\x72\x73\x65'
        ](JSON['\x73\x74\x72\x69\x6e\x67\x69\x66\x79'](a)),
        common['\x64\x72\x65\x61\x6d']['\x65\x6e\x63']['\x55\x74\x66\x38'][
          '\x70\x61\x72\x73\x65'
        ](b),
        {
          mode: common['\x64\x72\x65\x61\x6d']['\x6d\x6f\x64\x65'][
            '\x45\x43\x42'
          ],
          padding:
            common['\x64\x72\x65\x61\x6d']['\x70\x61\x64'][
              '\x50\x6b\x63\x73\x37'
            ]
        }
      )
      ['\x74\x6f\x53\x74\x72\x69\x6e\x67']()
  }
}

export const randomUUID = () => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = new Array(36)
  let rnd = 0,
    r
  for (let i = 0; i < 36; i++) {
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      uuid[i] = '-'
    } else if (i === 14) {
      uuid[i] = '4'
    } else {
      if (rnd <= 0x02) rnd = (0x2000000 + Math.random() * 0x1000000) | 0
      r = rnd & 0xf
      rnd = rnd >> 4
      uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
    }
  }
  return uuid.join('').replace(/-/gm, '').toLowerCase()
}

import 'reflect-metadata'

import Vue from 'vue'

import App from './App.vue'

import Antd from 'ant-design-vue'

import VueStoragePlugin from 'vue-ls'

import { I18n as i18n, I18NPlugin } from '@/i18n'

import { EventBusPlugin } from '@/event'

import HttpPlugin from '@/http'

import { common } from './http/common'

import '@/assets/styles/index.less'

import router from './router'

import store from './store'

import CryptoJS from 'crypto-js'

Vue.use(Antd)
Vue.use(I18NPlugin)
Vue.use(EventBusPlugin)
Vue.use(HttpPlugin)

Vue.prototype.$icrop = window.__ICOP__

common.dream = CryptoJS

// 基座的路由实例
window.__BASE_ROUTER__ = router

Vue.prototype.$__POWERED_BY_QIANKUN__ = window.__POWERED_BY_QIANKUN__

Vue.use(VueStoragePlugin, {
  namespace: 'root__',
  name: 'ls',
  storage: 'session'
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#rootApp')

import VueRouter, { Route } from 'vue-router'
import { SiteStateModule } from '@/store/site_state'
import { LOGIN_PAGE_ROUTE_NAME, LOGIN_PAGE_ROUTE_PATH } from '@/utils/constant'
import { eventBus } from '@/event'
import {
  EVENT_GLOBAL_GOTO_PAGE,
  PAGE_LOGIN,
  PAGE_MAIN
} from '@/event/event_types'
import { NaviStateModule } from '@/store/navi_state'

/**
 * 更新路由路径
 * @param path
 */
//TODO has mutation error, should fix
function notifyRoute(path: string) {
  console.log('notifyRoute', path, NaviStateModule.navi)
  //在开发环境路由中加入debug出口
  if (process.env.NODE_ENV === 'development' && path.endsWith('_debug')) return
  NaviStateModule.updateCurrentRoutePath(path)
}

export default class RouterGuide {
  hook(router: VueRouter) {
    router.afterEach((to: Route) => {
      console.log('afterEach', to, SiteStateModule.isValidAccount)
      if (SiteStateModule.isValidAccount) {
        //有效帐号
        if (
          to.name === LOGIN_PAGE_ROUTE_NAME ||
          to.path === LOGIN_PAGE_ROUTE_PATH
        ) {
          //有效帐号，却进入了登录页， 去首页
          eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, PAGE_MAIN)
        } else {
          notifyRoute(to.path)
        }
      } else if (
        to.name !== LOGIN_PAGE_ROUTE_NAME ||
        to.path !== LOGIN_PAGE_ROUTE_PATH
      ) {
        //非有效帐号，并且不是去登录页，去登录
        eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, PAGE_LOGIN)
      }
    })
  }
}

const guide = new RouterGuide()
export { guide }

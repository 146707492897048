enum MicroMessageState {
  UNREAD, //投递未读
  READ, //已读
  NEXT //传阅中
}

interface MicroController {
  registerMailBox(appName: string, mailbox: MicroMailBox): void
  unregisterMailBox(appName: string): void
  broadcast(message: any): void
  invoke(selector: string, message: MicroMessage): MicroMessage
}

interface MicroMessage {
  type: string
  state?: MicroMessageState
  data?: any
}

interface MicroMailBox {
  //处理广播
  onReceive(message: MicroMessage): void
  //处理调用
  onHandle(selector: string, message: MicroMessage): MicroMessage
}

interface MicroState {
  //总线是否被占用
  BUSY: boolean
  //是否有全局模态框
  MODAL: boolean
}

interface MicroAppConfig {
  name: string
  entry: string
  activeRule: string
  container: string
  props?: Record<string, any>
}

export {
  MicroMessageState,
  MicroMessage,
  MicroMailBox,
  MicroState,
  MicroAppConfig,
  MicroController
}

/**
 * 基座提供的控制器
 */
import {
  MicroController,
  MicroMailBox,
  MicroMessage,
  MicroMessageState
} from '@/micro/MicroTypes'

class BaseController implements MicroController {
  private _mailbox: Record<string, MicroMailBox> = {}

  /**
   * 挂载信箱
   * @param appName
   * @param mailbox
   */
  registerMailBox(appName: string, mailbox: MicroMailBox): void {
    this._mailbox[appName] = mailbox
  }

  /**
   * 注销信箱
   * @param appName
   */
  unregisterMailBox(appName: string) {
    delete this._mailbox[appName]
  }

  invoke(selector: string, message: MicroMessage): MicroMessage {
    console.log('BaseController invoke', selector, message)
    for (const mailboxKey in this._mailbox) {
      if (mailboxKey.toLowerCase() === selector.toLowerCase()) {
        return this._mailbox[mailboxKey].onHandle(selector, message)
      }
    }
    message.state = MicroMessageState.READ
    return message
  }

  broadcast(message: MicroMessage): void {
    console.log('BaseController broadcast', message)
    for (const mailboxKey in this._mailbox) {
      this._mailbox[mailboxKey].onReceive(message)
    }
  }
}

const defaultBaseController = new BaseController()
export default defaultBaseController

export async function isNewVersion() {
  const origin =
    process.env.NODE_ENV === 'production'
      ? `${location.origin}/micro-main`
      : 'http://localhost:7600/icop-main'
  const url = `${origin}/version.json?id=${new Date().getTime()}`
  fetch(url)
    .then((response) => response.json())
    .then((result) => {
      const version = result.version
      const localVersion = localStorage.getItem('version')
      if (localVersion && localVersion !== version.toString()) {
        localStorage.setItem('version', version.toString())
        window.location.reload()
      } else {
        localStorage.setItem('version', version.toString())
      }
    })
}

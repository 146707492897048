export const EVENT_GLOBAL_TOAST = 'EVENT_GLOBAL_TOAST'
export const EVENT_GLOBAL_GOTO_PAGE = 'EVENT_GLOBAL_GOTO_MAIN_PAGE'
export const PAGE_MAIN = 'PAGE_MAIN'
export const PAGE_LOGIN = 'PAGE_LOGIN'

export enum ToastConfigLevel {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  LOADING = 'loading'
}

export interface ToastConfig {
  level?: ToastConfigLevel
  msg: string
  args?: string | Array<string>
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LOCAL_STORAGE_ITEM } from '@/utils/constant'
import HyperSMSLocale from '@/assets/locale'
Vue.use(VueI18n)

const localeLang = HyperSMSLocale

function loadLang() {
  const appConfig = sessionStorage.getItem(LOCAL_STORAGE_ITEM.LS_APP_CONFIG)
  let lang = 'en-US'
  if (appConfig) {
    const appConfObj = JSON.parse(appConfig)
    if (appConfObj) {
      lang = appConfObj['i18n']
    }
  }
  return lang
}

const I18n = new VueI18n({
  silentTranslationWarn: true,
  locale: loadLang(),
  messages: localeLang,
  fallbackLocale: 'en-US'
})

const I18NPlugin = {
  install(Vue: Vue | any) {
    Vue.prototype.$textOnly = function (key: string) {
      return this.$t(key).toString()
    }

    Vue.prototype.$textAndParams = function (key: string, ...params: any) {
      return this.$t(key, params).toString()
    }

    Vue.prototype.$textAndColon = function (key: string) {
      return this.$t(key) + '：'
    }
  }
}

export { I18n, I18NPlugin }

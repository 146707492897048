import Vue from 'vue'

const eventBus = new Vue()

const EventBusPlugin = {
  install(Vue: any) {
    Vue.prototype.$eventBus = eventBus
  }
}

export { eventBus, EventBusPlugin }

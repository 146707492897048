import EN_US from './data/en_US.json'
import ZH_CN from './data/zh_CN.json'
import IN_US from './data/in_US.json'

import VI_VN from './data/vi_VN.json'

const lang = {
  'en-US': process.env.VUE_APP_THEME !== 'tablink' ? EN_US : IN_US,
  'zh-CN': ZH_CN,
  'vi-VN': VI_VN
}

export default lang

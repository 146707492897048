import Vue from 'vue'
import Vuex from 'vuex'
import SiteState from './site_state'
import NaviState from './navi_state'

Vue.use(Vuex)

interface StoreType {
  site: SiteState
  navi: NaviState
}

export default new Vuex.Store<StoreType>({})

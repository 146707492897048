import Vue from 'vue'
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router'
import { guide } from '@/router/guide'
import { LOGIN_PAGE_ROUTE_NAME } from '@/utils/constant'
import AppManifest from '../../public/manifest.json'
import { isNewVersion } from '@/utils/version'
Vue.use(VueRouter)

function lazyLoad(pagePath: string) {
  if (pagePath.indexOf('.vue') === -1) {
    pagePath += '.vue'
  }

  return import(
    /* webpackMode: "lazy" */
    /* webpackInclude: /\.vue/ */
    /* webpackChunkName: "view" */ '@/views/' + pagePath
  )
}

const comEntryLayout = () => lazyLoad('entry/pgEntry_layout.vue')
console.log('Process.env', process.env)
const comLogin = () => lazyLoad('entry/pgEntry_login.vue')
const comMbfLogin = () => lazyLoad('entry/pgEntry_mbf_login.vue')

const comMainLayout = () => lazyLoad('main/pgMain_layout.vue')

const routeComponentMap: Record<string, any> = {
  'client.biz.code.index': () => lazyLoad('home/home.vue')
}

console.log('base load manifest', AppManifest['routes'])
const appRouteConfig = AppManifest['routes']
appRouteConfig.forEach((item: any) => {
  const cp = routeComponentMap[item.name]
  if (cp !== undefined) {
    item.component = cp
  }
})

const routes: Array<RouteConfig> = [
  {
    path: '/entry',
    name: 'entry',
    component: comEntryLayout,
    children: [
      {
        path: 'login',
        name: LOGIN_PAGE_ROUTE_NAME,
        component:
          process.env.VUE_APP_THEME === 'vnmbf' ? comMbfLogin : comLogin
      }
    ]
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/index',
    redirect: '/home'
  },
  {
    path: '/',
    name: 'Content',
    component: comMainLayout,
    children: [...appRouteConfig]
  },
  {
    path: '/icop-main*',
    name: 'Content2',
    component: comMainLayout,
    children: []
  },
  {
    path: '/404',
    name: 'Error404',
    component: () =>
      // import(/* webpackChunkName: "exception" */ '../views/exception/404.vue'),
      lazyLoad('exception/page/404.vue'),
    meta: {
      auth: false,
      fullScreen: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
  isNewVersion()
})
router.beforeEach((to, from, next) => {
  if (
    to.name === 'client.biz.code.index' &&
    !sessionStorage.getItem('root__sec_token')
  ) {
    next({ path: '/entry/login' })
    return
  }
  if (to.name) {
    next()
  } else if (to.matched.length !== 0) {
    next()
  } else {
    next({ path: '/404' })
  }
})

const originalPush = VueRouter.prototype.push as unknown as Promise<Route>
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err)
}

guide.hook(router)

export default router

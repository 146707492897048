export const MAIN_PAGE_ROUTE_NAME = 'client.biz.code.brandNameList'
export const LOGIN_PAGE_ROUTE_NAME = 'client.biz.code.login'
export const HOME_PAGE_ROUTE_NAME = 'client.biz.code.index'
export const LOGIN_PAGE_ROUTE_PATH = '/entry/login'

export const LOCAL_STORAGE_ITEM = {
  LS_SERVER_INFO: 'serverInfo',
  LS_APP_CONFIG: 'app',
  LS_USER_INFO: 'userinfo',
  LS_SEC_TOKEN: 'sec_token',
  LS_SEC_MENU_LIST: 'sec_allow_menu',
  LS_SEC_PERMISSION_LIST: 'sec_allow_permissions',
  LS_SEC_MENUS_LIST: 'sec_allow_menulist'
}

export const LOCAL_STORAGE_KEEP_ITEM_LIST = [LOCAL_STORAGE_ITEM.LS_APP_CONFIG]
